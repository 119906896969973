import React, {Component} from "react";

class Error extends Component{

  render(){
    return (
      <div>
        <h1>pagina no encontrada</h1>
      </div>
    );
  }
}

export default Error;