import React, { Component } from "react";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";
import TituloPantalla1 from "./subComponents/TituloPantalla1";
import axios from 'axios';
import { qualifyService } from "../services/qualifyService";

class Informacion extends Component {

  state = {
    titulo: "",
    body: "",
    status: null
  }

  componentDidMount() {
    this.getBody();
  }

  getBody = () => {
    axios.get('/mocks/cobertura.json')
      .then((res) => {
        this.setState({
          body: res.data.body,
          titulo: res.data.title,
          status: 'succes'
        })
        // console.log(res.data)
        // console.log(this.state.body)
        // console.log(this.state.titulo)
      })
  }

  quote = (val) => {
    if (val) {
      qualifyService()
        .then(response => {

          if (sessionStorage.getItem('request_id') === null) sessionStorage.setItem('request_id', response.data.request_id);

          console.log(response.data);

          if (response.data.data.Typification.BLOCKED === "TRUE" && response.data.data.Typification.PROCESS === "1") {
            this.props.history.push({
              pathname: "/datos-vehiculo",
              // state: variable_to_transfer
            })
          }
          else if (response.data.data.Typification.BLOCKED === "FALSE" && response.data.data.Typification.PROCESS === "0") {
            this.props.history.push({
              pathname: "/datos-conductor",
              // state: variable_to_transfer
            })
          }
        });

      // const term = (sessionStorage.getItem('accept_term') == "true") ? true : false;
      // if (term) {
      //   // window.location.href = "/datos-conductor"
      //   this.props.history.push({
      //     pathname: "/datos-conductor",
      //     // state: variable_to_transfer
      //   })
      // } else {
      //   // window.location.href = "/datos-vehiculo"
      //   this.props.history.push({
      //     pathname: "/datos-vehiculo",
      //     // state: variable_to_transfer
      //   })
      // }
    }
    else
      window.location.href = "/"


  }

  render() {

    var conBody = "";
    var conTitulo = "";

    if (this.state.body !== "") {
      conBody = this.state.body;
      conTitulo = this.state.titulo;
    } else {
      conBody = "..."
      conTitulo = "..."
    }


    return (
      <div>
        <EncabezadoImagen />


        <TituloPantalla1 titulo={conTitulo} />

        <p className="text-justify" dangerouslySetInnerHTML={{ __html: conBody }}>

        </p>

        <div className="px-4 text-center">
          <h2 className="text-uppercase txtBlue">Tenemos opciones </h2>
          <br></br>
          <h3 className="text-uppercase txtBlue"> <b> desde </b> </h3>

          <h1 style={{ fontSize: "2.5rem" }} className="text-uppercase txtBlue">$400 </h1>
          <h3 className="text-uppercase txtBlue"> <strong>mensuales* </strong> </h3> 
          <p className=" txtBlue p-smaller">* Aplican restricciones</p>
          <br></br>
          <h4 className="text-uppercase txtBlue text-center">Personalice su cobertura </h4>

          <p>
            Complete su información para tener una cotización de acuerdo a su vehículo y podrá obtener mayor descuento si finaliza su compra.
          </p>
        </div>

        <br></br>


        <div className="text-center px-5">
          <p>
            <button type="button" className="btn btn-autos" onClick={(e) => this.quote(true, e)}>Cotice ahora</button>
          </p>
          <p>
            {/* <a href="#" className="a-autos" onClick={(e) => this.quote(false, e)}>En otro momento</a> */}
          </p>

        </div>

        <br></br>
        <br></br>

      </div>
    );
  }
}

export default Informacion;