import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TituloPantallaCotizacion from "./subComponents/TituloPantallaCotizacion";
import { Form, Input, Button, DatePicker, Space, Select } from 'antd';
import { qualifyService } from "../services/qualifyService";
import axios from 'axios';
import moment from 'moment';
import EncabezadoImagen from "./subComponents/EncabezadoImagen";


const DatosConductor = (props) => {

  const [status, setStatus] = useState("");
  const [ladas, setLadas] = useState([]);
  const [loadLadas, setloadLadas] = useState(false);
  const [form] = Form.useForm();
  const routerHistory = useHistory();

  const getLadas = () => {
    axios.get('/mocks/ladas.json')
      .then((res) => {
        setLadas(res.data);
        // console.log(JSON.stringify(res.data))
      })
  }

  const onFinish = (values) => {

    sessionStorage.setItem('client_name', values.name)
    console.log(sessionStorage.getItem('client_name'));

    sessionStorage.setItem('client_lastname', values.lastname)
    console.log(sessionStorage.getItem('client_lastname'));

    sessionStorage.setItem('client_lada', values.lada)
    console.log(sessionStorage.getItem('client_lada'));

    sessionStorage.setItem('client_lada', (sessionStorage.getItem('client_lada') === "undefined") ? "52" : sessionStorage.getItem('client_lada'));

    sessionStorage.setItem('client_phone', (sessionStorage.getItem('client_lada') === "undefined") ? "" : sessionStorage.getItem('client_lada') + values.phone)
    console.log(sessionStorage.getItem('client_phone'));

    sessionStorage.setItem('client_email', values.email)
    console.log(sessionStorage.getItem('client_email'));

    qualifyService()
      .then(response => {

        if (sessionStorage.getItem('request_id') === null) sessionStorage.setItem('request_id', response.data.request_id);

        console.log(response.data);

        routerHistory.push("/datos-vehiculo");
      });

  };

  function onChange(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);

    sessionStorage.setItem('client_date', dateString)
    console.log(sessionStorage.getItem('client_date'));

  };

  function cambioLada(value) {

    console.log(value)

    sessionStorage.setItem('client_lada', value.target.value)
    console.log(sessionStorage.getItem('client_lada'));

    // console.log(`selected ${value}`);
  }


  const initValues = {
    Marca: null,
    Modelo: null,
    Año: null,
    Version: null,
  }

  const dateFormat = 'DD-MM-YYYY';

  const { Option } = Select;

  // const selectBefore = (
  //   <Select defaultValue="52" >
  //     {ladas.map((data, index) =>
  //       <Option key={data.index} value={data.lada}>
  //         {data.lada} - {data.name}
  //       </Option>
  //     )}
  //   </Select>
  // );

  useEffect(() => {
    getLadas();
  }, [])

  return (
    <div>

      <EncabezadoImagen />
      <div className="px-3 wrap">

        <TituloPantallaCotizacion titulo="Datos del Conductor" />

        <div className="square-gray p-small p-bold text-justify txtGray">
          <p >AL COMPLETAR SU INFORMACIÓN PODRÍA OBTENER HASTA UN 25% DE DESCUENTO AL FINALIZAR SU COMPRA.</p>
        </div>

        <p className="txtGray">¿Quién conduce?</p>


        <Form
          // ref={this.formRef}
          form={form}
          name="control-ref"
          onFinish={onFinish}
          initialValues={initValues}
        >

          <Form.Item name="name" rules={[{ required: true, message: "Ingrese su nombre" }]}>
            <Input placeholder="Nombre (s)" />
          </Form.Item>

          <Form.Item name="lastname" rules={[{ required: true, message: "Ingrese sus apellidos" }]}  >
            <Input placeholder="Apellidos (s)" />
          </Form.Item>


          <Space direction="horizontal" style={{ width: '100%' }} >

            <Form.Item name="lada">
              <Select
                defaultValue="52"
                showSearch
                optionFilterProp="children"

                onChange={cambioLada}
                name="lada"
                placeholder="Lada"
              >
                {ladas.map((data, idx) =>
                  <Option key={idx} value={data.lada}>
                    {data.lada} - {data.name}
                  </Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item name="phone" rules={[{ required: true, message: "Ingrese su teléfono", pattern: new RegExp("^[0-9]{10,10}$") }]}  >


              <Input
                // addonBefore={(
                // )}
                placeholder="Teléfono" maxLength={10} />


            </Form.Item>
          </Space>

          <Form.Item name="email" rules={[{ required: true, type: 'email', message: "Ingrese su email" }]}>
            <Input placeholder="Correo electrónico" />
          </Form.Item>

          <Form.Item name="birthday" rules={[{ required: true, type: 'date', message: "Ingrese su fecha de nacimiento" }]}>
            <DatePicker onChange={onChange} name="birthday" style={{ width: '100%' }} format={dateFormat} placeholder="Fecha de nacimiento" disabledDate={(current) => current.isAfter(moment().subtract(1, "day"))} />
          </Form.Item>

          <br />

          <div className="text-center px-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>



          </div>
        </Form>
      </div>

      {/* <div className="p-small text-center txtGray">
        <p>* Aplican restricciones</p>
      </div> */}

    </div >
  );
}

export default DatosConductor;