import React, { Component } from "react";
import moment from 'moment';
import axios from 'axios';
import TituloPantallaCotizacion from "./subComponents/TituloPantallaCotizacion";
import { Form, Input, Checkbox, Button, Select, DatePicker, TimePicker } from 'antd';
import car from "./../assets/images/car.svg";

class CitaAsesorTerms extends Component {

  formRef = React.createRef();

  onChangePerm(e) {
    console.log(`val = ${e.target.checked}`);
  }

  onFinish = (values) => {
    console.log(values);


  };

  render() {

    const { Option } = Select;
    // const [form] = Form.useForm();

    const initValues = {

    }

    const dateFormat = 'DD-MM-YYYY';

    return (
      <div>
        <div className="wrap">

          <TituloPantallaCotizacion titulo="¡Gracias!" />

          {/* <p className="p-smaller p-bold txtGray">
            SI QUIERES CONOCER  SOBRE ESTA COBERTURA, GUARDA TU NÚMERO DE COTIZACIÓN: 4353252 AL  CONTACTAR CON EL ASESOR
          </p> */}

          <div className="text-center">
          <img src={car} />
          <br /><br /><br />
          <p className="p-upper txtBlue p-bold">
            Tus datos han sido <br />enviados
          </p>
          <br />
          <h3 className=" txtGreen p-bold">
            En breve se comunicará <br />
            un asesor para <br />
            darle detalles de<br />
            su cotización
          </h3>
        </div>



        </div>




      </div>
    );
  }
}

export default CitaAsesorTerms;