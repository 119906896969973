import React, {Component} from "react";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";
import TituloPantalla1 from "./subComponents/TituloPantalla1";
import axios from 'axios';

class PermisosFuncionalidad extends Component{

  state = {
    titulo: "",
    body: "",
    status: null
  }

  componentDidMount() {
    this.getBody();
  }

  getBody = () => {
    axios.get('/mocks/pantalla2.json')
      .then((res) => {
        this.setState({
          body: res.data.body,
          titulo: res.data.title,
          status: 'succes'
        })
        console.log(res.data)
        console.log(this.state.body)
        console.log(this.state.titulo)
      })
  }

  acceptFunction = (val) => {
    console.log("respuesta: "+ val);
    localStorage.setItem('accept_function',val)
    console.log(localStorage.getItem('accept_function'));
    // <Redirect to='/permisos-funcionalidad'  />
    window.location.href = "/informacion"
  }

  render() {

    var conBody = "";
    var conTitulo = "";

    if (this.state.body !== "") {
      conBody = this.state.body;
      conTitulo = this.state.titulo;
    } else {
      conBody = "No hay contenido"
      conTitulo = "No hay titulo"
    }


    return (
      <div>
        <EncabezadoImagen />


        <TituloPantalla1 titulo={conTitulo} />

        <p className="text-justify" dangerouslySetInnerHTML={{ __html: conBody }}>

        </p>

        <div className="text-center px-5">
          <p>
            <button type="button" className="btn btn-autos" onClick={(e) => this.acceptFunction(true, e)} >Aceptar</button>
          </p>
          <p>
            <a href="#" className="a-autos" onClick={(e) => this.acceptFunction(false, e)}>En otro momento</a>
          </p>

        </div>

      </div>
    );
  }
}

export default PermisosFuncionalidad;