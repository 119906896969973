import React, { Component, useState } from "react";
import { Modal, Button, Checkbox } from 'antd';
import TituloPantallaCotizacion from "./subComponents/TituloPantallaCotizacion";
import ModalApp from "./subComponents/ModalApp";
import ModalAppB from "./subComponents/ModalAppB";
import ModalConfirm from "./subComponents/ModalConfirm";
import axios from 'axios';
import API from '../api';


class Confirmacion extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal1Visible: false,
      checkedPriv: false,
      checkedPerm: false,
      checkedCall: false
    };

    this.onChangePriv = this.onChangePriv.bind(this);
    this.onChangePerm = this.onChangePerm.bind(this);
    this.onChangeCall = this.onChangeCall.bind(this);
  }

  componentDidMount() {

    const at = (sessionStorage.getItem('accept_term') == "true" ? true : false);
    const af = (sessionStorage.getItem('accept_function') == "true" ? true : false);
    const ac = (sessionStorage.getItem('accept_calls') == "true" ? true : false);

    this.setState({
      checkedPriv: at,
      checkedPerm: af,
      checkedCall: ac
    });

    if (at == false || af == false || ac == false) {
      this.setModal1Visible(true)
    }

  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  onChangePriv(e) {
    console.log(`Privacidad = ${e.target.checked}`);
    sessionStorage.setItem('accept_term', e.target.checked)
    this.setState({
      checkedPriv: !this.state.checkedPriv
    })
    console.log(`Privacidad State = ${this.state.checkedPriv}`);
  }

  onChangePerm(e) {
    console.log(`Permisos = ${e.target.checked}`);
    sessionStorage.setItem('accept_function', e.target.checked)
    this.setState({ checkedPerm: !this.state.checkedPerm });
    console.log(`Permisos State = ${this.state.checkedPerm}`);
  }

  onChangeCall(e) {
    console.log(`Llamadas = ${e.target.checked}`);
    sessionStorage.setItem('accept_calls', e.target.checked)
    this.setState({ checkedCall: !this.state.checkedCall });
    console.log(`Llamadas State = ${this.state.checkedCall}`);
  }

  onClickContinuar(e) {
    console.log('click', e);

    const at = (sessionStorage.getItem('accept_term') == "true" ? true : false);
    const af = (sessionStorage.getItem('accept_function') == "true" ? true : false);
    const ac = (sessionStorage.getItem('accept_calls') == "true" ? true : false);

    if (at == false || af == false || ac == false) {
      window.location.href = "/cita-asesor";
    } else {
      window.location.href = "/confirmacion";
    }
  }

  onClickNoporahora(e) {
    console.log('click', e);

    // sessionStorage.setItem('accept_term',valor.code)
    // sessionStorage.setItem('accept_function',valor.code)
    sessionStorage.setItem('accept_calls', false)

    const at = (sessionStorage.getItem('accept_term') == "true" ? true : false);
    const af = (sessionStorage.getItem('accept_function') == "true" ? true : false);
    const ac = (sessionStorage.getItem('accept_calls') == "true" ? true : false);

    if (at == false || af == false || ac == false) {
      window.location.href = "/cita-asesor";
    } else {
      window.location.href = "/confirmacion";
    }
  }

  onClickCotizacion(e) {
    console.log('click', e);

    const at = (sessionStorage.getItem('accept_term') == "true" ? true : false);
    const af = (sessionStorage.getItem('accept_function') == "true" ? true : false);
    const ac = (sessionStorage.getItem('accept_calls') == "true" ? true : false);

    if (at == false || af == false || ac == false) {
      window.location.href = "/cita-asesor";
    } else {

      const header = {
        'Content-Type': 'application/json',
        'affiliate': '51c110c2-7c25-49f6-972a-fe97a681f23e',
        'project': '4',
        'evaluar': 'S',
        'registrar': 'S',
      }

      const body = {
        'accept_term': false,
        'accept_function': false,
        'package_code': sessionStorage.getItem('package_code'),
        'package_period': sessionStorage.getItem('package_period'),
        'package_amount': sessionStorage.getItem('package_amount'),
        'package_text': sessionStorage.getItem('package_text'),
        'car_marca': sessionStorage.getItem('car_marca'),
        'car_model': sessionStorage.getItem('car_model'),
        'car_year': sessionStorage.getItem('car_year'),
        'car_version': sessionStorage.getItem('car_version'),
        'car_status': sessionStorage.getItem('car_status'),
        'car_cp': sessionStorage.getItem('car_cp'),
        'client_name': sessionStorage.getItem('client_name'),
        'client_lastname': sessionStorage.getItem('client_lastname'),
        'client_phone': sessionStorage.getItem('client_phone'),
        'client_email': sessionStorage.getItem('client_email'),
        'client_date': sessionStorage.getItem('client_date'),
        'payment_code': sessionStorage.getItem('payment_code'),
        'payment_amount': sessionStorage.getItem('payment_amount'),
        'payment_package': sessionStorage.getItem('payment_package'),
        'contact_accept_term': sessionStorage.getItem('contact_accept_term'),
        'contact_accept_contact': sessionStorage.getItem('contact_accept_contact'),
        'contact_accept_call': sessionStorage.getItem('contact_accept_call'),
        'contact_phone': sessionStorage.getItem('contact_phone'),
        'contact_date': sessionStorage.getItem('contact_date'),
        'contact_hour': sessionStorage.getItem('contact_hour')
      }

      const url = "https://servicioscmsdev.ketoro.digital:8094/qualify/8d812dcb-bd6a-4b4c-9327-63ba9e891df9";

      try {
        axios({
          method: 'post',
          url: url,
          headers: header , 
          data: body
        }).then((res) => {
          console.log(res.data);
          sessionStorage.clear()
          localStorage.clear()
        });
      } catch (e) {
        console.log(e.response); 
      }

    }
  }

  render() {

    return (
      <div>
        <TituloPantallaCotizacion titulo="Cotiza tu Seguro" />

        <p className="txtBlue p-subtitle text-center">
          Haz elegido la cobertura y plazo:
        </p>


        <div className="px-4">
          <h5 className="txtBlue">{sessionStorage.getItem('package_name')} </h5>
          <p className="p-small">
            Amplia Básica 10%robo RC <br />
            No pagas deducible en 1 accidente. <br />
            En caso de pérdida total, te pagamos tu auto y $10,000.00 más.
          </p>

          <p className="txtBlue"><strong>Pago {sessionStorage.getItem('package_period')}</strong></p>

          <strong className="txtGreen"> $  {sessionStorage.getItem('package_amount_desc')} </strong>
          <p className="p-small">{sessionStorage.getItem('package_no_payments')} pagos de {sessionStorage.getItem('package_amount_payments')} </p>

          <hr></hr>

          <p className="txtBlue"><strong>Restricciones</strong></p>
          <p className="p-small">
            Vehículo de uso particular.<br />
            Licencia o permiso para conducir vigente.<br />
            Tener a la mano: marca, modelo y tipo específico, número de serie o VIN y número de placas.<br />
            Si tu auto tiene placas de Estados Unidos, contar con el permiso temporal de importación vigente durante la vigencia de la póliza.<br />
            Si tu auto es Legalizado, contar con el pedimento de importación del mismo.<br />
            Si tu auto es Clásico, contar con un avalúo profesional para determinar su valor.<br />
          </p>
        </div>

        <br></br>


        <div className="text-center px-5">
          <p>
            <Button type="primary" onClick={this.onClickCotizacion} >
              Solicitar cotización
            </Button>
          </p>
        </div>


        {/* <Button type="primary" onClick={() => this.setModal1Visible(true)}>
          Display a modal dialog at 20px to Top
        </Button> */}

        <Modal
          title="Aceptar Terminos"
          // centered
          style={{ top: 200 }}
          visible={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          footer={[
            <Button
              key="submit"
              type="primary"
              style={{ width: 150 }}
              onClick={this.onClickNoporahora}
            >
              No por ahora
            </Button>,
            <Button
              style={{ width: 150 }}
              key="link"
              // href="https://google.com"
              onClick={this.onClickContinuar}
              type="primary"
            >
              Continuar
            </Button>,
          ]}
        >
          <p>Ketoro te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios y clientes que puedan ser recabados por la navegación o contratación de servicios a través del sitio Web www.ketoro.com</p>
          <p><Checkbox checked={this.state.checkedPriv} onChange={this.onChangePriv}>Acepto Politica de Privacidad</Checkbox></p>

          <p>Passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum has been the industry's standard dummy text eve</p>
          <p><Checkbox checked={this.state.checkedPerm} onChange={this.onChangePerm}>Permisos y funcionalidad</Checkbox></p>

          <p>En caso de ser necesario contactaremos via telefonica, uno de nuestros asesores se identificara y acredirara como parte de Ketoro, muchas gracias por su atención</p>
          <p><Checkbox checked={this.state.checkedCall} onChange={this.onChangeCall}>Acepto recibir llamadas de asesor ejecutivo</Checkbox></p>

        </Modal>
        <br />
        <br />


      </div>
    );
  }
}

export default Confirmacion;