import axios from 'axios';

export const qualifyService = async () => {

  // DESARROLLO
  // const url = "https://servicioscmsdev.ketoro.digital:8094/qualify/8d812dcb-bd6a-4b4c-9327-63ba9e891df9";
  // PRODUCCION
  const url = "https://servicioscms.ketoro.digital:8094/qualify/8d812dcb-bd6a-4b4c-9327-63ba9e891df9";


  const header = {
    'Content-Type': 'application/json',
    'affiliate': '51c110c2-7c25-49f6-972a-fe97a681f23e',
    'project': '4',
    'evaluar': 'S',
    'registrar': 'S',
    'request_id': sessionStorage.getItem('request_id')
  }

  const body = {
    'accept_term': sessionStorage.getItem('accept_term'),
    'accept_function': sessionStorage.getItem('accept_function'),
    'package_code': sessionStorage.getItem('package_code'),
    'package_period': sessionStorage.getItem('package_period'),
    'package_amount': sessionStorage.getItem('package_amount'),
    'package_text': sessionStorage.getItem('package_text'),
    'car_marca': sessionStorage.getItem('car_marca'),
    'car_model': sessionStorage.getItem('car_model'),
    'car_year': sessionStorage.getItem('car_year'),
    'car_version': sessionStorage.getItem('car_version'),
    'car_status': sessionStorage.getItem('car_status'),
    'car_cp': sessionStorage.getItem('car_cp'),
    'car_type': sessionStorage.getItem('car_type'),
    'car_commercial_use': sessionStorage.getItem('car_commercial_use'),
    'car_use_confirm': sessionStorage.getItem('car_use_confirm'),
    'client_name': sessionStorage.getItem('client_name'),
    'client_lastname': sessionStorage.getItem('client_lastname'),
    'client_phone': sessionStorage.getItem('client_phone'),
    'client_email': sessionStorage.getItem('client_email'),
    'client_date': sessionStorage.getItem('client_date'),
    'payment_code': sessionStorage.getItem('payment_code'),
    'payment_amount': sessionStorage.getItem('payment_amount'),
    'payment_package': sessionStorage.getItem('payment_package'),
    'contact_accept_term': sessionStorage.getItem('contact_accept_term'),
    'contact_accept_contact': sessionStorage.getItem('contact_accept_contact'),
    'contact_accept_call': sessionStorage.getItem('contact_accept_call'),
    'contact_phone': sessionStorage.getItem('contact_phone'),
    'contact_date': sessionStorage.getItem('contact_date'),
    'contact_hour': sessionStorage.getItem('contact_hour'),
    'ga_data': sessionStorage.getItem('ga_data'),
    'utm_medium': sessionStorage.getItem('utm_medium'),
    'utm_campaign': sessionStorage.getItem('utm_campaign'),
    'utm_source': sessionStorage.getItem('utm_source')
  }

  let response = await axios({
    method: 'post',
    url: url,
    headers: header,
    data: body
  });

  // try {
  //   axios({
  //     method: 'post',
  //     url: url,
  //     headers: header,
  //     data: body
  //   }).then(res => {
  //     response = res.data
  //     console.log(res.data)
  //   });
  // } catch (e) {
  //   console.log(e.response);
  // }

  return response // or set initial value
}
