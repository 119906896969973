import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { getDataCustomer } from './reducers';
import { createStore } from 'redux';
// Fingerprint
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
// Google Analitics
import ReactGA from 'react-ga';
// TagManager
import TagManager from 'react-gtm-module';

const store = createStore(getDataCustomer);
// console.log(store.getState());

// Fingerprint initialize
const fpPromise = FingerprintJS.load({
  token: 'OemkJAC8g0lyTMyhj86k'
})

if (sessionStorage.getItem('device_id') == null) {
  fpPromise
    .then(fp => fp.get())
    .then(result => {

      sessionStorage.setItem('device_id', result.visitorId);
    })
} else {
  console.log("device_id == " + sessionStorage.getItem('device_id'));
}

// Geolocalitation initialize
// if ("geolocation" in navigator) {
//   console.log("Available");
// } else {
//   console.log("Not Available");
// }

if (sessionStorage.getItem('latitude') == null || sessionStorage.getItem('longitude') == null) {
  navigator.geolocation.getCurrentPosition(function (position) {
    sessionStorage.setItem('latitude', position.coords.latitude);
    sessionStorage.setItem('longitude', position.coords.longitude);
  });
} else {
  console.log("Latitude is :", sessionStorage.getItem('latitude'));
  console.log("Longitude is :", sessionStorage.getItem('longitude'));
}

// if (sessionStorage.getItem('os') == null || (sessionStorage.getItem('os')) ) {

const userAgentSO = window.navigator.userAgent;
let so = "";

if (userAgentSO.search('Win') !== -1) {
  so = "windows";
}
if (userAgentSO.search('Linux') !== -1) {
  so = "linux";
}
if (userAgentSO.search('Mac') !== -1) {
  so = "mac";
}
if (so === "") {
  const platform = window.navigator.platform;

  if (platform.search('Win') !== -1) {
    so = "windows";
  }
  if (platform.search('Linux') !== -1) {
    so = "linux";
  }
  if (platform.search('Mac') !== -1) {
    so = "mac";
  }
}

sessionStorage.setItem('os', so);

// console.log("SO :", so + " | " + window.navigator.userAgent + " | " + window.navigator.platform + " | " + window.navigator.appVersion);
// } else {
// console.log("OS : " + sessionStorage.getItem('os'));
// }

// Google Analitics initialize
ReactGA.initialize('UA-221157324-1');

// TagManager initialize
TagManager.initialize({ gtmId: 'GTM-PM7MC89' });


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
