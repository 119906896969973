const INITIAL_STATE = {
  accept_term: false,
  accept_function: false,
  package_code: null,
  package_period: null,
  package_amount: null,
  package_text: null,
  car_marca: null,
  car_model: null,
  car_year: null,
  car_version: null,
  car_status: null,
  car_cp: null,
  client_name: null,
  client_lastname: null,
  client_phone: null,
  client_email: null,
  client_date: null,
  payment_code: null,
  payment_amount: null,
  payment_package: null,
  contact_accept_term: null,
  contact_accept_contact: null,
  contact_accept_call: null,
  contact_phone: null,
  contact_date: null,
  contact_hour: null
}

export function getDataCustomer(state = INITIAL_STATE, action) {
  // console.log(JSON.stringify(action));
  // console.log(action);
  // console.log(state.accept_term);
  switch (action.type) {
    case 'TERMINOS':
      return {
        accept_term: true
      }
    default:
      return state
  }
}