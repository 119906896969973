import React, {Component} from "react";
import { NavLink } from "react-router-dom";

class Menu extends Component{

  render(){

    return (
      <div>
        <nav>
            {/* <NavLink activeClassName="active" to="/">Inicio</NavLink>  | 
            <NavLink activeClassName="active" to="/terminos-condiciones">Terminos</NavLink>  | 
            <NavLink activeClassName="active" to="/permisos-funcionalidad">Permisos</NavLink> | 
            <NavLink activeClassName="active" to="/informacion">Informacion</NavLink> |  */}
            {/* <NavLink activeClassName="active" to="/cobertura">Cobertura</NavLink>  |   */}
            {/* <NavLink activeClassName="active" to="/datos-vehiculo">Datos Vehiculo</NavLink>  |  
            <NavLink activeClassName="active" to="/datos-conductor">Datos Conductor</NavLink>  |  */}
            {/* <NavLink activeClassName="active" to="/confirmacion">Confirmacion</NavLink>  |  */}
            {/* <NavLink activeClassName="active" to="/cita-asesor">Cita Asesor</NavLink>  | 
            <NavLink activeClassName="active" to="/thanks-page">Thanks</NavLink>   */}
        </nav>
      </div>
    );
  }
}

export default Menu;