import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TerminosCondiciones from './components/TerminosCondiciones';
import PermisosFuncionalidad from './components/PermisosFuncionalidad';
import Inicio from './components/Inicio';
import Error from './components/Error';
import Menu from './components/Menu';
import Informacion from './components/Informacion';
import CoberturaForm from './components/CoberturaForm';
import DatosVehiculo from './components/DatosVehiculo';
import DatosConductor from './components/DatosConductor';
import Confirmacion from './components/Confirmacion';
import CitaAsesor from './components/CitaAsesor';
import ThanksPage from './components/ThanksPage';
import CitaAsesorTerms from './components/CitaAsesorTerms';
import BloqueoOperativo from './components/BloqueoOperativo';
import Politica from './components/Politica';
// import TerminosContainer from './containers/TerminosContainer';

class Router extends Component {
  render() {

    return (
        <BrowserRouter>
          <Menu />
          <Switch>
            <Route exact path="/" component={Inicio} />
            <Route exact path="/terminos-condiciones" component={TerminosCondiciones} />
            <Route exact path="/politica-privacidad" component={Politica} />
            <Route exact path="/permisos-funcionalidad" component={PermisosFuncionalidad} />
            <Route exact path="/informacion" component={Informacion} />
            <Route exact path="/cobertura" component={CoberturaForm} />
            <Route exact path="/datos-vehiculo" component={DatosVehiculo} />
            <Route exact path="/datos-conductor" component={DatosConductor} />
            <Route exact path="/confirmacion" component={Confirmacion} />
            <Route exact path="/cita-asesor" component={CitaAsesor} />
            <Route exact path="/cita-asesor-terms" component={CitaAsesorTerms} />
            <Route exact path="/thanks-operative" component={BloqueoOperativo} />
            <Route exact path="/thanks-page" component={ThanksPage} />
            <Route component={Error} />
          </Switch>
        </BrowserRouter>
    );
  }
}

export default Router;