import React, { Component } from "react";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";
import TituloPantalla1 from "./subComponents/TituloPantalla1";
import axios from 'axios';
import { Form, Button, Checkbox } from 'antd';


class TerminosCondiciones extends Component {

  state = {
    titulo: "",
    p1: "",
    p2: "",
    status: null
  }

  componentDidMount() {
    // this.getBody();
  }

  // getBody = () => {
  //   axios.get('/mocks/pantalla1.json')
  //     .then((res) => {
  //       this.setState({
  //         p1: res.data.p1,
  //         p2: res.data.p2,
  //         titulo: res.data.title,
  //         status: 'succes'
  //       })
  //       // console.log(res.data)
  //       // console.log(this.state.body)
  //       // console.log(this.state.titulo)
  //     })
  // }

  acceptTerms = (val) => {
    // console.log("respuesta: "+ val);
    // sessionStorage.setItem('accept_term', val)
    // console.log(sessionStorage.getItem('accept_term'));

    // console.log(this.props.location.pathname);

    if (val) {
      // window.location.href = "/informacion"
      this.props.history.push({
        pathname: "/informacion",
        // state: variable_to_transfer
      })
    } else {
      // window.location.href = "/informacion"
      this.props.history.push({
        pathname: "/informacion",
        // state: variable_to_transfer
      })
    }
  }

  viewConditions = () => {

    this.props.history.push({
      pathname: "/politica-privacidad",
      // state: variable_to_transfer
    })

  }

  onFinishTerms = (values) => {

    sessionStorage.setItem('accept_term', (typeof values.accept_term !== 'undefined') ? values.accept_term : false)
    sessionStorage.setItem('contact_accept_call', (typeof values.contact_accept_call !== 'undefined') ? values.contact_accept_call : false)

    this.props.history.push({
      pathname: "/informacion",
      // state: variable_to_transfer
    })

  };

  render() {

    let p1 = "";
    let p2 = "";
    var conTitulo = "";

    if (this.state.p1 !== "") {
      p1 = this.state.p1;
      p2 = this.state.p2;
      conTitulo = this.state.titulo;
    } else {
      p1 = "No hay contenido"
      p2 = "No hay contenido"
      conTitulo = "No hay titulo"
    }


    return (

      <div>
        <EncabezadoImagen />


        <TituloPantalla1 titulo="Terminos Y Condiciones" />


        <Form
          ref={this.formRef}
          // form={form}
          name="control-ref"
          onFinish={this.onFinishTerms}
        >


          <p>
            Al ingresar a este Sitio, KETORO podrá obtener y usar datos de carácter personal, los cuales serán protegidos y tratados en términos de nuestra Política de Privacidad.
            Para conocer nuestra Política de Privacidad haga <a href="#" className="a-autos" onClick={(e) => this.viewConditions()} > clic aquí. </a> </p>

          <Form.Item
            name="accept_term"
            valuePropName="checked"
          // rules={[
          //   {
          //     validator: (_, value) =>
          //       value ? Promise.resolve() : Promise.reject(new Error('Certifique el tipo de uso')),
          //   },
          // ]}
          >
            <p><Checkbox>Acepto Política de Privacidad</Checkbox></p>
          </Form.Item>



          <p> Para darle una mejor experiencia de nuestros servicios uno de nuestros asesores podrán contactarlo vía telefónica,  quien se identificará como agente de Seguros el Águila Compañía de Seguros. </p>

          <Form.Item
            name="contact_accept_call"
            valuePropName="checked"
          // rules={[
          //   {
          //     validator: (_, value) =>
          //       value ? Promise.resolve() : Promise.reject(new Error('Certifique el tipo de uso')),
          //   },
          // ]}
          >
            <p><Checkbox> Acepto recibir llamadas</Checkbox></p>
          </Form.Item>



          <div className="text-center px-5">
            <p>
              {/* <button type="button" className="btn btn-autos" onClick={(e) => this.acceptTerms(true, e)}>Aceptar</button> */}

              <Form.Item>
                <Button type="primary" htmlType="submit" >
                  Continuar
                </Button>
              </Form.Item>


            </p>
            <p>
              <a href="#" className="a-autos" onClick={(e) => this.acceptTerms(false, e)} >En otro momento</a>
            </p>

          </div>

        </Form>

      </div>
    );
  }
}

export default TerminosCondiciones;