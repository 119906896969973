import React, {Component} from "react";

class TituloPantalla1 extends Component{

  render(){

    const titulo = this.props.titulo;

    return (
      <React.Fragment>
        <br/>
        <h2 className="text-uppercase txtBlue text-center">{titulo}</h2>
        <br/>
      </React.Fragment>
    );
  }
}

export default TituloPantalla1;