import React, { Component } from "react";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";
import car from "./../assets/images/car.svg";

class ThanksPage extends Component {

  acceptOK = (val) => {
    // console.log("respuesta: "+ val);
    sessionStorage.setItem('accept_term', val)
    // console.log(sessionStorage.getItem('accept_term'));

    console.log(this.props.location.pathname);

    if (val) {
      // window.location.href = "/informacion"
      this.props.history.push({
        pathname: "/",
        // state: variable_to_transfer
      })
    } else {
      // window.location.href = "/informacion"
      this.props.history.push({
        pathname: "/",
        // state: variable_to_transfer
      })
    }
  }

  downloadTxtFile = () => {

    let card = "";

    card += "BEGIN:VCARD\n";
    card += "VERSION:4.0\n";
    // card += "N:Gump;Forrest;;Mr.;";
    // card += "FN:Forrest Gump";
    card += "ORG:Mi Seguro\n";
    card += "TITLE:Contacto Mi Seguro\n";
    // card += "PHOTO;MEDIATYPE=image/gif:http://www.example.com/dir_photos/my_photo.gif";
    card += "TEL;TYPE=work,voice;VALUE=uri:tel:+34555005500\n";
    // card += "TEL;TYPE=home,voice;VALUE=uri:tel:+1-404-555-1212";
    // card += 'ADR;TYPE=WORK;PREF=1;LABEL="100 Waters Edge\nBaytown\, LA 30314\nUnited States of America":;;100 Waters Edge;Baytown;LA;30314;United States of America';
    // card += 'ADR;TYPE=HOME;LABEL="42 Plantation St.\nBaytown\, LA 30314\nUnited States of America":;;42 Plantation St.;Baytown;LA;30314;United States of America';
    card += "EMAIL:miseguro@example.com\n";
    card += "REV:20080424T195243Z\n";
    card += "x-qq:21588891\n";
    card += "END:VCARD";

    const data = new Blob([card], { type: 'text/vcard' });

    // const element = document.createElement("a");
    const element = document.createElement("a");
    element.href = URL.createObjectURL(data);
    element.download = "contact.vcard";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }


  render() {

    return (
      <div>

        <EncabezadoImagen />

        <br />

        <div className="text-center">
          {/* <img src={car} /> */}
          <br /><br /><br />
          <p className="p-upper txtBlue p-bold">
            Sus datos han sido <br />enviados
          </p>
          <br />
          <h3 className=" txtGreen p-bold">
            En breve se comunicará <br />
            un asesor para <br />
            darle detalles de<br />
            su cotización
          </h3>
        </div>

        <br></br>

        <div className="text-center">
          <p>
            <button type="button" id="downloadVcard" className="btn btn-autos" onClick={(e) => this.downloadTxtFile()} >Descargar Contacto</button>
            <br />
            <br />
            <button type="button" className="btn btn-autos" onClick={(e) => this.acceptOK(true, e)}>Entendido</button>
          </p>
        </div>




      </div>
    );
  }
}

export default ThanksPage;