import React, { Component } from "react";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";
import TituloPantalla1 from "./subComponents/TituloPantalla1";
import axios from 'axios';

class Politica extends Component {

  state = {
    titulo: "",
    p1: "",
    p2: "",
    status: null
  }

  componentDidMount() {
    this.getBody();
  }

  getBody = () => {
    axios.get('/mocks/pantalla1.json')
      .then((res) => {
        this.setState({
          p1: res.data.p1,
          p2: res.data.p2,
          titulo: res.data.title,
          status: 'succes'
        })
        // console.log(res.data)
        // console.log(this.state.body)
        // console.log(this.state.titulo)
      })
  }


  backPrevious = () => {

    this.props.history.push({
      pathname: "/terminos-condiciones",
      // state: variable_to_transfer
    })

  }

  render() {

    let p1 = "";
    let p2 = "";
    var conTitulo = "";

    if (this.state.p1 !== "") {
      p1 = this.state.p1;
      p2 = this.state.p2;
      conTitulo = this.state.titulo;
    } else {
      p1 = "No hay contenido"
      p2 = "No hay contenido"
      conTitulo = "No hay titulo"
    }


    return (

      <div>
        <EncabezadoImagen />


        <TituloPantalla1 titulo="AVISO DE PRIVACIDAD" />


        <p className="p-justify">
          A efecto de dar cumplimiento a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la "LFPDPPP"), KETORO DIGITAL LLC., (en adelante “KETORO”), le informa que en KETORO protegemos y salvaguardamos sus datos personales para evitar el daño, pérdida, destrucción, así como el tratamiento no autorizado de sus datos personales. KETORO, con domicilio en Av. Fuentes de Satélite No. 1, Edificio Marsella, Departamento 204, Colonia Bellavista Satélite, C. P. 54054, en Tlalnepantla de Baz, Estado de México, actúa como responsable de sus datos personales.
        </p>

        <p className="p-justify">
          Por medio del presente, usted queda informado de los términos y condiciones del Aviso de Privacidad de Datos Personales ("Aviso de Privacidad") de KETORO.
        </p>
        <p className="p-justify">
          Derivado de lo anterior, le informamos que en el momento que usted se registra o visita nuestro servidor (Portal), usted manifiesta su consentimiento expreso a que sus datos personales sean tratados de acuerdo con los términos y condiciones de este Aviso de Privacidad.
        </p>
        <p className="txtGreen p-bold">DATOS RECOPILADOS.</p>

        <p className="p-justify">
          Los datos personales que usted libre y voluntariamente proporcione a KETORO de forma física o a través del website ("Portal"), mediante la utilización de nuestros servicios en línea o al ponerse en contacto con KETORO, por vía telefónica o por escrito, podrán incluir de manera enunciativa más no limitativa: (i) datos personales, como lo son: nombre, domicilio, teléfono, edad, entre otros, (ii) datos financieros, (iii) datos informáticos, y/o para fines de los servicios (iv) datos generales tales como tipo de vehículo, marca, año.
        </p>
        <p className="p-justify">
          Tratándose de los terceros cuyos datos personales recopilamos a través de usted, igualmente tomamos las medidas de seguridad y de confidencialidad para el tratamiento de su información, de conformidad con los términos y condiciones en este Aviso de Privacidad.
        </p>
        <p className="p-justify">
          Al momento de visitar, llenar y suscribir el registro de datos fue informado de las condiciones sobre protección de datos de carácter personal y de que, por el hecho de suscribir dicho registro, emitía su aceptación y consentimiento al tratamiento de los mismos por parte de KETORO, en la forma y para las finalidades indicadas en el presente Aviso de Protección de Datos Personales.
        </p>
        <p className="p-justify">
          En la recolección y tratamiento de datos personales que usted nos proporcione, cumplimos todos los principios que marca la LFPDPPP (artículo 6): licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.
        </p>
        <p className="p-justify">
          Dicha información la recopilamos a través de cookies y/o web beacons, como a continuación se explica:
          Cookies: son archivos descargados automáticamente y almacenados por su navegador en el disco duro al visitar el PORTAL. Las "cookies" permiten al servidor conocer si un usuario visita por primera vez los Sitios o ha visitado los Sitios anteriormente, qué áreas de los Sitios son de mayor interés para el usuario, así como las preferencias de visualización. El depósito de las "cookies" por parte del PORTAL puede mejorar su experiencia en línea y también pueden medir la actividad general en los Sitios, lo que nos permite determinar qué áreas y características son las más populares. Esta información nos permite hacer mejoras en nuestros PORTALES, y volverlos más dinámicos.
          Web Beacons: También conocidos como "clear gifs", son pequeñas imágenes gráficas en una página Web o en un correo electrónico que nos permiten monitorear la actividad en los Sitios o hacer las "cookies" más eficientes. Los "Web Beacons" nos permiten a nosotros, o a terceros que trabajan en nuestro nombre, evaluar y mejorar la eficacia de nuestras campañas de marketing mediante la transmisión de información acerca de si el usuario sigue los links en nuestros correos electrónicos o páginas Web, conocer la información sobre la dirección IP del usuario, el tiempo de interacción en nuestra página y para la recopilación de información sobre los sitios que visita antes y después de visitar nuestra Web. Nosotros usamos los archivos de registro para almacenar los datos que se recopilan a través de "Web Beacons".
          La mayoría de los navegadores o exploradores Web están configurados para aceptar "cookies". Sin embargo, Usted puede configurar su navegador para rechazar todos los "cookies" o para indicar cuando un "cookie" está siendo enviado. Si decide rechazar los "cookies", algunas de las funciones del PORTAL pueden verse afectadas.
        </p>
        <p className="txtGreen p-bold">
          USO Y/O FINALIDAD DE LA INFORMACIÓN RECOPILADA.
        </p>
        <p className="p-justify">
          Los datos personales que recopilamos de los usuarios que se registran y acceden a nuestros servicios en internet, por teléfono o de forma presencial, son incorporados a nuestra base de datos y  destinados para los siguientes propósitos: (i) Analís, (ii) contacto, (iii) promoción de nuestros bienes y servicios, así como de aquéllos que comercializan nuestras empresas subsidiarias y afiliadas y nuestros distintos proveedores, (iv) actualización de los servicios, (v) encuestas, (vi) facilidad de uso del PORTAL, (vi) fines estadísticos, y/o (vii) evaluación de la calidad de los servicio, así como cualquier finalidad análoga o compatible con las anteriores, siendo las anteriores mencionadas de manera enunciativas mas no limitativas. Los datos personales que usted proporcione a KETORO, podrán compilarse y fijarse en una base de datos propiedad exclusiva de KETORO, en la cual se desasociaran sus datos personales y sólo se utilizará para proveer a KETORO información tal como cuántos usuarios visitaron el SITIO, las horas de mayor tráfico, y las categorías a las que accedieron. La recopilación de esta información nos ayuda a adaptar nuestros SITIO a nuestros visitantes y sus necesidades para darle un mejor funcionamiento.
          KETORO podrá contratar a uno o varios terceros, con quienes previamente hayamos celebrado contratos con cláusulas de confidencialidad y de protección de datos personales, como proveedores de servicios seleccionados para apoyar las actividades de promoción y comercialización de nuestros bienes y servicios, manejo y administración de los datos personales que se recaba a través de este Portal y mediante llamadas por teléfono, o por cualquier otro medio distinto, así como para fines de verificación de que la información que usted nos proporciona es correcta y actual, y poder ofrecerle nuevos servicios y/o cumplir con los ya convenidos, por lo que KETORO podría incluso transferir sus datos personales a dicho(s) tercero(s). De igual forma, le informamos que podemos combinar los Datos Personales recolectados en el PORTAL con otra información acerca de Usted y los usuarios que recopilamos a través de otros medios. Cualquier transferencia de datos personales que KETORO realice, será únicamente para los fines permitidos por las leyes.
        </p>
        <p className="p-justify">
          KETORO podrá sin fines comerciales, compartir o transmitir sus datos personales con sus empresas subsidiarias, filiales, matriz, controladoras y/o empresas colaboradoras, para los mismos fines establecidos en este punto. En ningún caso comercializaremos, venderemos o rentaremos sus Datos Personales a un tercero sin su consentimiento previo.
          Se entiende que ha otorgado su consentimiento a la transferencia de sus datos personales, si no manifiesta oposición a que los mismos sean transferidos.
        </p>

        <p className="p-justify">
          Las ligas a sitios externos de este portal, no son responsabilidad de KETORO, por lo que no asumimos responsabilidad alguna con respecto al contenido y políticas de privacidad en dichos sitios.
        </p>
        <p className="txtGreen p-bold">
          MEDIDAS DE SEGURIDAD Y CONTROL PARA LA PROTECCIÓN DE DATOS PERSONALES.
        </p>
        <p className="p-justify">
          KETORO tiene implementadas medidas de seguridad administrativas, técnicas y físicas para proteger sus datos personales, mismas que igualmente exigimos sean cumplidas por los proveedores de servicios que contratamos, inclusive tratándose de servicios que prestan las empresas subsidiarias o afiliadas de KETORO.
        </p>
        <p className="p-justify">
          Toda la información agregada y datos personales que se obtengan de usted a través del uso de nuestra página Web (www.______.com) constituirá una base de datos propiedad de KETORO, información que se almacena para protegerla y evitar su pérdida, uso indebido, o alteración. No obstante lo anterior, KETORO en ninguna forma garantiza su seguridad, ni que la misma pueda ser interceptada, alterada o sustraída por terceros.
        </p>
        <p className="p-justify">
          KETORO puede utilizar "cookies" o "direcciones de IP" para confirmar su identificación al tener acceso a nuestro Portal, con el solo propósito de otorgarle un servicio personalizado debido a que nos permite determinar sus preferencias mediante el registro de su uso en nuestro Portal por parte de los usuarios y de esta manera identificar sus hábitos y preferencias para ofrecerle promociones y campañas publicitarias de nuestros servicios y mercancías. Mediante el uso de las "cookies", no recabamos datos personales de los usuarios en términos de la Ley.
        </p>
        <p className="txtGreen p-bold">
          ÁREA RESPONSABLE DE LA PROTECCIÓN DE DATOS PERSONALES.
        </p>
        <p className="p-justify">
          El área responsable del manejo y la administración de los datos personales es el: "Departamento de Protección de Datos Personales", a quien puede contactar mediante el correo electrónico _____________.com o directamente en nuestras oficinas ubicadas en Av. Fuentes de Satélite No. 1, Edificio Marsella, Depto. 204, Colonia Bellavista Satélite, C. P. 54054, Tlalnepantla de Baz, Estado de México
        </p>
        <p className="txtGreen p-bold">
          5.     DERECHOS DE ACCESO, CANCELACIÓN, RECTIFICACIÓN Y OPOSICIÓN AL TRATAMIENTO DE DATOS PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO OTORGADO.
        </p>
        <p className="p-justify">
          En términos de la LFPDPPP, usted podrá iniciar el procedimiento de protección de derechos de acceso, cancelación, rectificación y oposición al tratamiento de sus datos personales (en lo sucesivo “derechos ARCO”) ante KETORO, o bien, revocar el consentimiento que usted haya otorgado a KETORO, para el tratamiento de sus datos personales, enviando directamente su solicitud al departamento de "Protección de Datos Personales" a través de la cuenta de correo electrónico: ________________.com. Dicha solicitud deberá contener por lo menos: (a) nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud; (b) los documentos que acrediten su identidad o, en su caso, la representación legal; (c) la descripción clara y precisa de los datos personales respecto de los que se solicita ejercer alguno de los derechos ARCO, (d) la manifestación expresa para revocar su consentimiento al tratamiento de sus datos personales y por tanto, para que no se usen; (e) cualquier otro elemento que facilite la localización de los datos personales. KETORO comunicará al titular, en un plazo máximo de veinte días, contados desde la fecha en que se recibió la solicitud de acceso, rectificación, cancelación u oposición, la determinación adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los quince días siguientes a la fecha en que se comunica la respuesta. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso. La solicitud de protección de datos también procederá en los mismos términos cuando el Responsable no haga efectivo el ejercicio al titular de los derechos ARCO ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales.
        </p>
        <p className="p-justify">
          Para el caso de que la solicitud sea presentada por una persona distinta al usuario, deberá presentar carta poder expedida por el Titular de los datos personales, suscrita ante dos testigos y anexando copia simple de la identificación oficial del usuario y del representante legal.
        </p>
        <p className="txtGreen p-bold">
          6.    MEDIDAS PARA LA PROTECCIÓN DE LA INFORMACIÓN
        </p>
        <p className="p-justify">
          KETORO tiene implementadas medidas de seguridad administrativas, técnicas y físicas para proteger sus datos personales, mismas que igualmente exigimos sean cumplidas por los proveedores de servicios que contratamos.
        </p>
        <p className="p-justify">
          Toda la información agregada y datos personales que se obtengan de usted será integrada a la Base de datos de KETORO, cuya información que se almacena para protegerla y evitar su pérdida, uso indebido, o alteración. No obstante lo anterior, no podemos garantizar la seguridad de nuestro SITIO, bases de datos o servicios, ni podemos garantizar que la información que usted suministra no será interceptada mientras es transmitida a través de Internet. No nos hacemos responsables por las acciones de terceros.
        </p>
        <p className="txtGreen p-bold">
          7.     MODIFICACIONES AL AVISO DE PRIVACIDAD
        </p>
        <p className="p-justify">
          Nos reservamos el derecho de cambiar este Aviso de Privacidad en cualquier momento. En caso de que exista algún cambio en este Aviso de Privacidad, se le comunicará a través de nuestro Portal.
        </p>
        <p className="txtGreen p-bold">
          8.     AVISO LEGAL
        </p>
        <p className="p-justify">
          Independientemente del compromiso de KETORO para dar un manejo responsable y seguro a la información de los datos personales del usuario, en los términos del presente Aviso, se les informa que:
        </p>
        <p className="p-justify">
          Para la interpretación y cumplimiento de los anteriores términos y condiciones de seguridad, privacidad y legalidad, tanto el Titular de los datos personales como KETORO, estarán a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y sus normas complementarias y en caso de controversia aceptan sujetarse a la jurisdicción de los tribunales competentes de la Cuidad de México, renunciando desde ahora al fuero que pudiera corresponderles en razón de sus domicilios presentes o futuros o por cualquier otra causa.
        </p>
        <p className="p-justify">
          Fecha de actualización: _____ de 2022.
        </p>

        <TituloPantalla1 titulo="TERMINOS Y CONDICIONES DEL USO DE LA PLATAFORMA" />

        <p className="p-justify">
          KETORO LLC por medio del presente establece los términos y condiciones de este Sitio web. Este
          “sitio web” únicamente tiene la intención de recolectar información de los usuarios o visitantes
          del sitio web (“usted”) con la finalidad de proporcionar información comercial, ofertas, productos
          y/o servicios de seguros, en especial los relacionados con el ámbito automotriz y en su caso
          brindar el acceso de comunicación con el prestador del servicio final (“La Aseguradora”), por lo
          que no se utiliza para ningún otro propósito. Los términos acordados por KETORO y Usted serán
          proporcionados siempre y cuando acepte los términos, condiciones y avisos que se ponen a su
          disposición por lo que deberá leer cuidadosamente los Términos de uso.
        </p>
        <p className="p-justify">
          Al acceder y/o utilizar este Sitio Web la función del mismo a través de un formulario que usted
          debe completar relacionado con el tipo de automóvil, modelo, entidad de circulación, entre otros
          datos necesarios para el procesamiento de información, este Sitio Web podrá ofrecerle a Usted, la
          posibilidad de acceder a productos, servicios, ofertas y promociones proporcionadas por La
          Aseguradora, siendo que, acepta que dichos datos sean transferidos a La Aseguradora para que
          ésta cuente con el perfil y en su caso contacte con Usted.
        </p>
        <p className="p-justify">
          En el supuesto que usted decida contratar, contactar o aceptar ser contactado por La
          Aseguradora, deberá sujetarse y aceptar el Aviso de Privacidad y Términos y Condiciones de La
          Aseguradora, toda vez que es un agente externo a KETORO.
        </p>
        <p className="txtGreen p-bold">
          USO DEL SITIO WEB
        </p>
        <p className="p-justify">
          Para el uso de este sitio web usted declara cumplir los siguientes:
          <ul>
            <li> Tener por lo menos 18 años de edad;</li>
            <li> Tenerla facultad legal para crear una obligación legal vinculante;</li>
            <li> Toda la información que suministres en este sitio web es verdadera, exacta y completa;</li>
            <li>Ser responsable de la cuenta registrada en el sitio web y proteger la información de tu
              cuenta, así como del nombre de usuario y contraseña. </li>
          </ul>
        </p>
        <p className="txtGreen p-bold">
          ACTIVIDADES PROHIBIDAS
        </p>
        <p className="p-justify">
          El uso del sitio deberá ser estrictamente para el uso y condiciones para el que esta diseñado, por
          lo que, el Usuario tiene prohibido:
        </p>
        <ul>
          <li> Modificar, copiar, distribuir, transmitir, exhibir, presentar, reproducir, publicar, autorizar,
            alterar, transferir, vender ni revender información, software, productos o servicios
            obtenidos a través de este sitio web.</li>
          <li> Utilizar este sitio web ni su contenido para cualquier propósito comercial;</li>
          <li> Proporcionar información falsa o fraudulenta;</li>
          <li> Acceder, controlar o copiar cualquier contenido o información de este sitio web utilizando
            algún robot, araña, redireccionador u otros medios automatizados o cualquier proceso
            manual para cualquier propósito sin nuestro permiso expreso por escrito;</li>
          <li> Violar las restricciones de cualquier encabezado de exclusión de robots de este sitio web o
            evitar o eludir otras medidas empleadas para prevenir o limitar el acceso a este sitio web;</li>
        </ul>
        <p className="p-justify">
          Si su cuenta presenta signos de fraude, abuso o actividad sospechosa, KETORO puede cancelar
          dicha cuenta o la operación que se haya efectuado a través de la cuenta.
        </p>
        <p className="txtGreen p-bold">
          RESTRICCIONES DE KETORO
        </p>
        <p className="p-justify">
          KETORO no brinda los servicios finales relacionados en este Sitio Web, su función es únicamente
          procesar los datos que Usted proporcione, con la finalidad de brindarle una mejor experiencia
          respecto a los productos y/o servicios que se ajusten a sus necesidades relacionadas con la
          obtención de un seguro de auto de conformidad con la información que haya proporcionado en
          este Sitio Web, por lo que KETORO no es el responsable de brindar el servicio final, ni se pondrá en
          contacto con usted para vender directamente algún producto o servicio anunciado, ni realizará
          ningún tipo de cobro relacionado a los productos o servicios ofertados.
        </p>
        <p className="txtGreen p-bold">
          POLÍTICA DE PRIVACIDAD
        </p>
        <p className="p-justify">
          En cumplimiento a la protección de datos KETORO pone a tu disposición nuestra Política de
          privacidad actual, a través de ___[link ]_______
        </p>
        <p className="txtGreen p-bold">
          DESLINDE DE RESPONSABILIDAD
        </p>
        <p className="p-justify">
          KETORO no es el prestador de servicios final, por lo que KETORO no realizará la prestación del
          servicio final ofrecido en este Sitio Web, ni realizará el contacto directo con Usted, ni realizará
          ningún tipo de cobro relacionado con los productos y/o servicios ofertados, por lo que, una vez
          que Usted desee contratar alguno de los servicios ofrecidos o contactar con La Aseguradora
          deberá leer y aceptar el aviso de privacidad y términos y condiciones de dichos sitios.
        </p>
        <p className="txtGreen p-bold">
          VÍNCULOS A SITIOS DE TERCEROS
        </p>
        <p className="p-justify">
          Este sitio web puede contener hipervínculos a sitios web operados por proveedores de servicios
          vinculados. No controlamos estos sitios web ni tampoco somos responsables de su contenido o de
          la privacidad u otras prácticas de dichos sitios web.
        </p>
        <p className="txtGreen p-bold">
          SOFTWARE DISPONIBLE EN ESTE SITIO WEB
        </p>
        <p className="p-justify">
          Cualquier software que pongamos a disposición para descargar desde este sitio web (&quot;Software&quot;)
          esta protegido por derechos de autor de _____________. El uso de dicho Software se rige por los
          términos del contrato de licencia del usuario final,
          El Software, incluyendo sin limitación, todo el código HTML y los controles Active X incluidos en
          este sitio web, es propiedad de _______y está protegido por las disposiciones de las leyes de
          derecho de autor y de tratado internacional. Cualquier reproducción o redistribución del Software
          está expresamente prohibido y puede provocar serias penalizaciones en materia civil y penal. Los
          infractores serán procesados en el máximo alcance posible.
        </p>
        <p className="p-justify">
          SIN PERJUICIO DE LO MENCIONADO ANTERIORMENTE, QUEDA EXPRESAMENTE PROHIBIDO
          COPIAR O REPRODUCIR EL SOFTWARE EN CUALQUIER OTRO SERVIDOR O UBICACIÓN PARA QUE
          AHÍ SE REPRODUZCA O REDISTRIBUYA.
          Tu dispositivo móvil debe estar conectado a internet para que la Aplicación móvil funcione
          correctamente.
        </p>
        <p className="txtGreen p-bold">
          CIERRE DE LA CUENTA
        </p>
        En el supuesto que KETORO detecte un mal uso por parte de Usted que contravenga cualquier
        disposición aplicable a la protección de datos personales, derechos de autor, propiedad industrial
        o actividades engañosas o fraudulentas relacionadas con los productos y/o servicios ofertados en
        este Sitio Web, KETORO se reserva el derecho a no prestar los servicios y a ejercer cualquier acción
        legal en contra del usuario responsable.
        <p className="txtGreen p-bold">
          SERVICIO DE AYUDA
        </p>
        Poner datos de contacto
        MODIFICACIONES
        KETORO puede modificar estos Términos de uso en cualquier momento y la Política de Privacidad,
        las que podrá consultar en todo momento en este sitio web.

        <br></br>
        <br></br>

        <div className="text-center px-5">
          <p className="p-justify">
            <button type="button" className="btn btn-autos" onClick={(e) => this.backPrevious()}>Regresar</button>
          </p>
        </div>

        <br></br>
        <br></br>
        <br></br>

      </div>
    );
  }
}

export default Politica;