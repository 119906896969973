import React, { Component } from "react";
import logo from "./../../assets/images/logo_elaguila.PNG";


class EncabezadoImagen extends Component {

  render() {
    return (

      // <div className="txtBlue" id="logoTxt">
      //   <div className="mi">Mi</div>
      //   <div className="seguro" >Seguro <span className="de">de</span></div>
      //   <div className="auto">Auto</div>

      // </div>
      <div className="text-center">
        <img src={logo} width="50%"></img>
      </div>
    );
  }
}

export default EncabezadoImagen;