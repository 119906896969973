import React, { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./../assets/css/style-slick.css";
import axios from 'axios';
import { Collapse, Form, Radio, Button, Row, Col } from 'antd';

const CoberturaForm = (props) => {

  const [listPackages, setlistPackages] = useState([]);
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const getPackages = () => {
    axios.get('/mocks/paquetes.json')
      .then((res) => {
        setlistPackages(res.data.paquetes);
        console.log(res.data.paquetes)
      })
  }

  useEffect(() => {
    getPackages();
  }, [])

  const settings = {
    // className: "slider variable-width",
    dots: false,
    // infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 0,
    variableWidth: true
  };

  const packages = listPackages.map((data, i) =>
    <div style={{ width: 300 }}>
      {/* style={{ width: 300 }} */}
      <div className="px-3">
        <h2>{data.tipo}</h2>
        <p>{data.caracteristicas}</p>

        <p>
          <a href="#" className="a-autosMin">Ver Caracteristicas</a>
        </p>

      </div>

      <Collapse bordered={true} defaultActiveKey={[data.code + '0', data.code + '1', data.code + '2', data.code + '3', data.code + '4']}>
        {
          data.tipos_pago.map((p, pi) =>
            <Panel header={p.tipo} key={data.code + pi}>

              <Row>
                <Col span={4}>
                  <Radio value={"{\"paquete\":\""+data.code+"\",\"tipo\":\""+p.tipo+"\"}"}></Radio>
                </Col>
                <Col span={12}>

                  <h4> {p.monto_desc} </h4>
                  <p className="p-small">4 pagos de $2,772.76</p>
                </Col>
                <Col span={8}>
                  <h4> {p.descuento} </h4>
                  <p className="p-line p-bold" >{p.monto_real}</p>
                </Col>
              </Row>

            </Panel>
          )
        }
      </Collapse >

    </div>

  );

  const onFinish = (value) => {
    console.log(value.package);
    const pt = JSON.parse(JSON.stringify(value.package));
    const p = JSON.parse(pt);
    console.log(p.paquete);
    console.log(p.tipo);

    listPackages.forEach( function(valor, indice) {
      if(valor.code == p.paquete){

        // console.log("Valor List Paquetes " +  valor.code  + " Seleccion " +p.paquete);

        sessionStorage.setItem('package_code',valor.code)
        // console.log(sessionStorage.getItem('package_code'));

        sessionStorage.setItem('package_name',valor.tipo)
        // console.log(sessionStorage.getItem('package_code'));

        sessionStorage.setItem('package_features',valor.caracteristicas)
        // console.log(sessionStorage.getItem('package_code'));

        valor.tipos_pago.forEach( function(v, i) {
          // console.log("Valor List Paquetes " +  v.tipo  + " Seleccion " +p.tipo);
          if(v.tipo == p.tipo){
            // console.log("Valor List Paquetes " +  v.tipo  + " Seleccion " +p.tipo);
            sessionStorage.setItem('package_period',v.tipo)
            // console.log(sessionStorage.getItem('package_period'));

            sessionStorage.setItem('package_amount',v.monto_real)
            // console.log(sessionStorage.getItem('package_amount'));

            sessionStorage.setItem('package_amount_desc',v.monto_desc)
            // console.log(sessionStorage.getItem('package_amount'));

            sessionStorage.setItem('package_no_payments',v.no_pagos)
            // console.log(sessionStorage.getItem('package_amount'));

            sessionStorage.setItem('package_amount_payments',v.monto_pagos)
            // console.log(sessionStorage.getItem('package_amount'));
    
          }
        });
      }
    });
    window.location.href = "/confirmacion" ;
  }



const initValues = {
  // Marca: null,
  // Modelo: null,
  // Año: null,
  // Version: null,
}


return (
  <div>
    <br />
    <div className="text-center">
    <p className="txtGray p-bold">Selecciona la cobertura de tu interes</p>
    </div>
    <br />
    <Form
      // ref={this.formRef}
      form={form}
      name="control-ref"
      onFinish={onFinish}
      initialValues={initValues}
    >
      <Form.Item name="package" rules={[{ required: true, message: 'Seleccione una cobertura' }]}>
        <Radio.Group name="package" >
          <Slider {...settings}>
            {packages}
          </Slider>
        </Radio.Group>
      </Form.Item>

      <div className="text-center px-5">
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Seleccionar
          </Button>
        </Form.Item>
      </div>
    </Form>
  </div >
);
}

export default CoberturaForm;
