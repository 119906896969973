import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Radio, Button, Select, Checkbox } from 'antd';
import TituloPantallaCotizacion from './subComponents/TituloPantallaCotizacion';
import axios from 'axios';
import API from '../api';
import { qualifyService } from "../services/qualifyService";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";

const DatosVehiculo = (props) => {

  const { Option } = Select;
  const [form] = Form.useForm();
  const routerHistory = useHistory();

  const [listStates, setlistStates] = useState([]);
  const [listBrands, setlistBrands] = useState([]);
  const [listModels, setlistModels] = useState([]);
  const [listYears, setlistYears] = useState([]);
  const [listVersions, setlistVersions] = useState([]);
  const [brandSelected, setbrandSelected] = useState(0);
  const [modelSelected, setmodelSelected] = useState(0);
  const [yearSelected, setyearSelected] = useState(0);
  const [versionSelected, setversionSelected] = useState(0);

  const getBrands = () => {
    API.post('getCarsByBrand')
      .then(res => {
        // this.setState({
        //   listBrands: res.data.data.listCarsByBrand,
        //   status: 'ok'
        // })
        setlistBrands(res.data.data.listCarsByBrand);
      })
  }

  const getStates = () => {
    axios.get('/mocks/estados.json')
      .then((res) => {
        setlistStates(res.data);
      })
  }

  const onChangeBrand = (value) => {
    form.resetFields(["Modelo", "Año", "Version"]);
    API.post('getCarsByModel?idBrand=' + value)
      .then(res => {
        setbrandSelected(value)
        setlistModels(res.data.data.listCarsByModel)
      })
  }

  const onChangeModel = (value) => {
    form.resetFields(["Year", "Version"]);
    API.post('getCarsByYear?idBrand=' + brandSelected + '&idModel=' + value)
      .then(res => {
        setmodelSelected(value);
        setlistYears(res.data.data.listCarsByYear);
      })
  }

  const onChangeYear = (value) => {
    form.resetFields(["Version"]);
    API.post('getCarsByVersion?idBrand=' + brandSelected + '&idModel=' + modelSelected + '&idYear=' + value)
      .then(res => {
        setyearSelected(value)
        setlistVersions(res.data.data.listCarsByVersion);
      })
  }

  const onReset = () => {
    form.resetFields();
  };

  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const onFinishCar = (values) => {

    sessionStorage.setItem('car_type', values.Tipo)
    // console.log(sessionStorage.getItem('car_type'));

    sessionStorage.setItem('car_marca', values.Marca)
    // console.log(sessionStorage.getItem('car_marca'));

    sessionStorage.setItem('car_model', values.Modelo)
    // console.log(sessionStorage.getItem('car_model'));

    sessionStorage.setItem('car_year', values.Year)
    // console.log(sessionStorage.getItem('car_year'));

    sessionStorage.setItem('car_version', values.Version)
    // console.log(sessionStorage.getItem('car_version'));

    sessionStorage.setItem('car_cp', values.CP)
    // console.log(sessionStorage.getItem('car_cp'));

    sessionStorage.setItem('car_costo', values.Costos)
    // console.log(sessionStorage.getItem('car_costo'));

    sessionStorage.setItem('car_commercial_use', values.use_confirm)
    // console.log(sessionStorage.getItem('car_commercial_use'));

    sessionStorage.setItem('no_cotizacion', random(1000000, 9900000))
    // console.log(sessionStorage.getItem('no_cotizacion'));

    qualifyService()
      .then(response => {
        console.log(response.data);

        if (sessionStorage.getItem('request_id') === null) sessionStorage.setItem('request_id', response.data.request_id);

        // const term = (sessionStorage.getItem('accept_term') == "true") ? true : false;
        // sessionStorage.clear();
        // localStorage.clear();

        if (response.data.data.Typification.BLOCKED === "FALSE" && response.data.data.Typification.PROCESS === "0") {
          routerHistory.push("/thanks-page");
        }
        else if (response.data.data.Typification.BLOCKED === "TRUE" && response.data.data.Typification.PROCESS === "1") {
          routerHistory.push("/cita-asesor");
        }
        else if (response.data.data.Typification.BLOCKED === "TRUE" && response.data.data.Typification.PROCESS === "2") {
          routerHistory.push("/thanks-operative");
        }

        // if (term) {
        //   // window.location.href = "/thanks-page";
        //   routerHistory.push("/thanks-page");
        // } else {
        //   // window.location.href = "/cita-asesor"
        //   routerHistory.push("/cita-asesor");
        // }

      });

  };

  const initValues = {
    Marca: null,
    Modelo: null,
    Año: null,
    Version: null,
  }

  useEffect(() => {
    getBrands();
    getStates();
    console.log(navigator);
  }, [])

  return (
    <div>

      <EncabezadoImagen />

      <div className="px-3 wrap">

        <TituloPantallaCotizacion titulo="Datos del Vehiculo" />

        <Form
          // ref={this.formRef}
          form={form}
          name="control-ref"
          onFinish={onFinishCar}
          initialValues={initValues}
        >

          <p className="txtBlue p-subtitle">
            Uso del vehículo
          </p>

          <div className="row formulario text-center">
            <Form.Item name="Tipo" rules={[{ required: true, message: "Ingrese el tipo de uso" }]}>
              <Radio.Group buttonStyle="solid" style={{ marginTop: 16 }}>
                <Radio.Button value="particular">Particular</Radio.Button>
                <Radio.Button value="empresa">Empresa</Radio.Button>
                <Radio.Button value="comercial">Comercial</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

          <br />

          <p className="txtBlue p-subtitle">
            ¿Que auto quiere asegurar?
          </p>

          <div className="form-group">
            <Form.Item name="Marca" rules={[{ required: true, message: "Ingrese la marca" }]}>
              <Select
                className="select-data-car"
                showSearch
                placeholder="Marca"
                optionFilterProp="children"
                onChange={onChangeBrand}
                // onChange={e => { this.onChangeBrand(); useHandleBranchChange() }}
                // onSearch={this.onSearchBrand}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {listBrands.map((listaCon, index) =>
                  <Option value={listaCon.idBrand}>
                    {listaCon.brand}
                  </Option>
                )}

              </Select>
            </Form.Item>
          </div>

          <div className="form-group">
            <Form.Item name="Modelo" rules={[{ required: true, message: "Ingrese el modelo" }]}>
              <Select
                // value={this.state.modelSelected}
                className="select-data-car"
                showSearch
                placeholder="Modelo"
                optionFilterProp="children"
                onChange={onChangeModel}
                // onSearch={this.onSearchModel}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {listModels.map((listaCon, index) =>
                  <Option value={listaCon.idModel}>
                    {listaCon.model}
                  </Option>
                )}

              </Select>
            </Form.Item>
          </div>

          <div className="form-group">
            <Form.Item name="Year" rules={[{ required: true, message: "Ingrese el año" }]}>
              <Select
                className="select-data-car"
                showSearch
                placeholder="Año"
                optionFilterProp="children"
                onChange={onChangeYear}
                // onSearch={this.onSearchYear}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {listYears.map((listaCon, index) =>
                  <Option value={listaCon.idYear}>
                    {listaCon.year}
                  </Option>
                )}

              </Select>
            </Form.Item>
          </div>

          <div className="form-group">
            <Form.Item name="Version" rules={[{ required: true, message: "Ingrese la versión" }]}>
              <Select
                className="select-data-car"
                showSearch
                placeholder="Versión"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <option value="0">NINGUNA</option>

                {listVersions.map((listaCon, index) =>
                  <Option value={listaCon.version}>
                    {listaCon.version}
                  </Option>
                )}

              </Select>
            </Form.Item>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <Form.Item name="Estado" rules={[{ required: true, message: "Ingrese el estado" }]}>
                  <Select
                    className="select-data-car"
                    showSearch
                    placeholder="Estado"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {listStates.map((listaCon, index) =>
                      <Option value={listaCon.clave}>
                        {listaCon.nombre}
                      </Option>
                    )}

                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="col">
              <div className="form-group cp">
                <Form.Item name="CP" rules={[{ required: true, message: "Ingrese el C.P.", pattern: new RegExp("^[0-9]{5,5}$") }]}>
                  <Input placeholder="C.P." />
                </Form.Item>
              </div>
            </div>
          </div>

          <br /><br />

          {/* <Form.Item name="use_confirm" rules={[{ required: true, message: "Certifique el tipo de uso" }]}> */}
          <Form.Item
            name="use_confirm"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Certifique el tipo de uso')),
              },
            ]}
          >
            <p><Checkbox>Certifico que mi vehículo no es de uso comercial (Uber, Didi, otros) ni salvamento (recuperado por Pérdida Total)</Checkbox></p>
          </Form.Item>


          <br />
          <br />

          <div className="text-center px-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Continuar
              </Button>
            </Form.Item>



          </div>
        </Form>

      </div >

      <br></br>
      <br></br>

    </div >
  );

};

export default DatosVehiculo;
