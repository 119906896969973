import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/css/App.css';
// Importar Componentes
import Router from './Router'


const App = () => {

  return (

    <div className="App container">
        <Router />
    </div>
  );
}

export default App;
