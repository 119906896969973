import React, { Component } from "react";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";

class Inicio extends Component {


  componentDidMount() {
    // setInterval(() => window.location.href = "/terminos-condiciones", 1000);
    // setInterval(() => window.history.replaceState( {} , "Terminos y Condiciones", "/terminos-condiciones" ) , 1000);
    // window.history.pushState({"html":"","pageTitle": "Terminos y Condiciones"},"", "/terminos-condiciones");   
    // window.history.pushState('terminos-condiciones', 'Terminos y condiciones', '/terminos-condiciones'); 

    // console.log(this.props.location.pathname);

    // if (this.props.location.pathname === "/") {

    sessionStorage.clear();
    localStorage.clear();

    sessionStorage.setItem('utm_medium', this.getParameterByName('utm_medium'))
    sessionStorage.setItem('utm_campaign', this.getParameterByName('utm_campaign'))
    sessionStorage.setItem('utm_source', this.getParameterByName('utm_source'))
    sessionStorage.setItem('ga_data', this.getCookieValue('_ga'))


    setTimeout(() => {
      this.props.history.push({
        pathname: "/terminos-condiciones",
        // state: variable_to_transfer
      })
    }, 2000);
    // }

  }

  getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  )

  render() {
    return (
      <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <EncabezadoImagen />
      </div>
    );
  }
}

export default Inicio;