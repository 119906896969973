import React, { Component } from "react";
import moment from 'moment';
import axios from 'axios';
import TituloPantallaCotizacion from "./subComponents/TituloPantallaCotizacion";
import { Form, Input, Checkbox, Button, Select, DatePicker, TimePicker } from 'antd';
import { qualifyService } from "../services/qualifyService";
import EncabezadoImagen from "./subComponents/EncabezadoImagen";

class CitaAsesor extends Component {

  formRef = React.createRef();

  hours() {
    var hours = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24];
    return hours;
  }

  onChangePerm(e) {
    console.log(`val = ${e.target.checked}`);
  }

  onChange(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);

    sessionStorage.setItem('contact_date', dateString)
    console.log(sessionStorage.getItem('contact_date'));

  };

  onChangeHour(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);

    sessionStorage.setItem('contact_hour', dateString)
    console.log(sessionStorage.getItem('contact_hour'));

  };

  onFinish = (values) => {
    console.log(values);

    sessionStorage.setItem('contact_phone', values.phone)
    // console.log(sessionStorage.getItem('contact_phone'));

    // sessionStorage.setItem('contact_date', values.date)
    // console.log(sessionStorage.getItem('contact_date'));

    // sessionStorage.setItem('contact_hour', values.hour)
    // console.log(sessionStorage.getItem('contact_hour'));

    sessionStorage.setItem('contact_accept_call', values.confirmCalls)
    // console.log(sessionStorage.getItem('contact_accept_call'));    

    qualifyService()
      .then(response => {
        console.log(response.data);

        this.props.history.push({
          pathname: "/thanks-page",
          // state: variable_to_transfer
        })


      });


    // window.location.href = "/thanks-page";
  };

  disabledDates(current) {
    // Can not select Sundays and predefined days
    return (
      moment(current).day() === 0 ||
      moment(current).day() === 6 ||
      current.isBefore(moment().subtract(1, "day"))
    );
  }

  render() {

    const { Option } = Select;
    // const [form] = Form.useForm();

    const initValues = {

    }

    return (
      <div>
        <EncabezadoImagen />
        <div className="wrap">

          <TituloPantallaCotizacion titulo="Contacta un asesor" />

          <p className="p-smaller p-bold txtGray">
            SI QUIERE CONOCER  SOBRE ESTA COBERTURA, GUARDA TU NÚMERO DE COTIZACIÓN: {sessionStorage.getItem('no_cotizacion')} AL  CONTACTAR CON EL ASESOR
          </p>

          <div className="card w-100">
            <div className="card-body">
              <p className="card-text">Para nosotros son muy importantes tus necesidades, nuestro horario de atenciones es de  L- V DE 8 a 20 hrs.</p>

              {/* de lunes a viernes de 9 a 21 */}
              {((moment().weekday() !== 6 && moment().weekday() !== 7) && (moment().hours() >= 9 && moment().hours() <= 21)) ? (

                <div className="text-center">
                  <p>
                    {/* <button type="button" className="btn btn-autos">Llamar ahora</button> */}
                    <a className="btn btn-autos" href="tel:+525554888888">Llamar ahora</a>
                  </p>
                </div>
              ) : (<div></div>)
              }


            </div>
          </div>

          <br />

          <div className="card w-100">
            <div className="card-body">


              <Form
                // ref={this.formRef}
                ref={this.formRef}
                // form={form}
                name="control-ref"
                onFinish={this.onFinish}
                initialValues={initValues}
              >

                <Form.Item name="phone" rules={[{ required: true, message: "Ingrese su telefono", pattern: new RegExp("^[0-9]{10,10}$") }]}>
                  <Input placeholder="Tel / Cel" maxLength={10} />
                </Form.Item>

                <Form.Item name="date" rules={[{ required: true, type: 'date', message: "Ingrese la fecha para agendar llamada" }]}>
                  <DatePicker

                    onChange={this.onChange} name="date" style={{ width: '100%' }} format={'DD-MM-YYYY'} placeholder="Agendar Llamada "
                    // disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                    disabledDate={this.disabledDates}
                  />
                </Form.Item>

                <Form.Item name="hour" rules={[{ required: true, message: "Ingrese la hora para agendar llamada" }]}>
                  <TimePicker onChange={this.onChangeHour} defaultOpenValue={moment('08:00', 'HH:mm')} format={'HH:mm'} style={{ width: '100%' }} placeholder="Hora" disabledHours={this.hours} />
                </Form.Item>

                <br />

                <Form.Item
                  name="confirmCalls"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Acepte recibir llamadas')),
                    },
                  ]}
                >
                  <p><Checkbox onChange={this.onChangePerm} >Acepto recibir llamadas de asesor ejecutivo Ketoro.</Checkbox></p>
                </Form.Item>

                <br />

                <div className="text-center px-5">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Siguiente
                    </Button>
                  </Form.Item>



                </div>
              </Form>

            </div>
          </div>



        </div>

      </div>
    );
  }
}

export default CitaAsesor;