import axios from 'axios';

export default axios.create({
  // baseURL: 'http://localhost:8086/'
  // baseURL: 'http://3.132.5.208:8097/'
  // Desarrollo
  baseURL: 'https://servicioscmsdev.ketoro.digital:8097/'
  // Produccion
  // baseURL: 'https://servicioscms.ketoro.digital:8094/'

  
  
});

