import React, { Component } from "react";
import axios from 'axios';
import TituloPantallaCotizacion from "./subComponents/TituloPantallaCotizacion";
import { Form, Input, Button, DatePicker, Space, Select } from 'antd';
import EncabezadoImagen from "./subComponents/EncabezadoImagen";
import moment from 'moment';

class BloqueoOperativo extends Component {

  state = {
    ladas: []
  }

  componentDidMount() {
    this.getLadas();
  }

  getLadas() {
    axios.get('/mocks/ladas.json')
      .then((res) => {
        this.setState({
          ladas: res.data,
          status: 'succes'
        })
        console.log(res.data)
      })
  }

  formRef = React.createRef();

  onChangePerm(e) {
    console.log(`val = ${e.target.checked}`);
  }

  onFinish = (values) => {
    console.log(values);

    this.props.history.push({
      pathname: "/",
      // state: variable_to_transfer
    })
  };

  cambioLada(value) {

    console.log(value)

    sessionStorage.setItem('client_lada', value.target.value)
    console.log(sessionStorage.getItem('client_lada'));

    // console.log(`selected ${value}`);
  }

  render() {

    sessionStorage.clear();
    localStorage.clear();

    const { Option } = Select;
    // const [form] = Form.useForm();

    const initValues = {

    }

    const dateFormat = 'DD-MM-YYYY';

    return (
      <div>
        <div className="wrap">

          <EncabezadoImagen />


          <TituloPantallaCotizacion titulo="Contacta un asesor" />

          <p className="p-smaller p-bold txtGray">
            SI QUIERES CONOCER  SOBRE ESTA COBERTURA, GUARDA TU NÚMERO DE COTIZACIÓN: {sessionStorage.getItem('no_cotizacion')} AL  CONTACTAR CON EL ASESOR
          </p>

          <div class="card w-100">
            <div class="card-body">
              <p class="card-text">Para nosotros son muy importantes tus necesidades, nuestro horario de atenciones es de  L- V DE 9 a 21 hrs.</p>

              {/* de lunes a viernes de 9 a 21 */}
              {((moment().weekday() !== 6 && moment().weekday() !== 7) && (moment().hours() >= 9 && moment().hours() <= 21)) ? (

                <div className="text-center">
                  <p>
                    {/* <button type="button" className="btn btn-autos">Llamar ahora</button> */}
                    <a className="btn btn-autos" href="tel:+525554888888">Llamar ahora</a>
                  </p>
                </div>
              ) : (<div></div>)
              }


            </div>
          </div>

          <br />

          <div class="card w-100">
            <div class="card-body">


              <Form
                // ref={this.formRef}
                ref={this.formRef}
                // form={form}
                name="control-ref"
                onFinish={this.onFinish}
                initialValues={initValues}
              >
                {/* 
                <Form.Item name="phone" rules={[{ required: true, message: "Ingrese su telefono" }]}>
                  <Input placeholder="Tel / Cel" />
                </Form.Item> */}

                <Space direction="horizontal" style={{ width: '100%' }} >

                  <Form.Item name="lada">
                    <Select
                      defaultValue="52"
                      showSearch
                      optionFilterProp="children"

                      onChange={this.cambioLada}
                      name="lada"
                      placeholder="Lada"
                    >
                      {this.state.ladas.map((data, idx) =>
                        <Option key={idx} value={data.lada}>
                          {data.lada} - {data.name}
                        </Option>
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item name="phone" rules={[{ required: true, message: "Ingrese su teléfono", pattern: new RegExp("^[0-9]{10,10}$") }]}  >


                    <Input
                      // addonBefore={(
                      // )}
                      placeholder="Teléfono" maxLength={10} />


                  </Form.Item>
                </Space>

                <br />

                <div className="text-center px-5">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Acepto
                    </Button>
                  </Form.Item>



                </div>
              </Form>

            </div>
          </div>



        </div>

      </div>
    );
  }
}

export default BloqueoOperativo;